import React from 'react'
import './style.css'
import { RiBarChart2Fill, RiDashboardFill, RiFileList2Fill, RiFileList3Fill, RiGroupFill, RiLineChartLine } from "react-icons/ri";
import { Link, useLocation } from 'react-router-dom';

export default function SideBar({user}) {
    const {pathname} = useLocation()
    if(user){
        return (
            <div className='SideBar'>
                <div className='Logo'/>
                <Link to={'/'} className={pathname === '/'? 'SideBar_Con SideBar_Con_Select' : 'SideBar_Con'}>
                    <RiGroupFill size={18}/>
                    <p>Users</p>
                </Link>
                <Link to={'/answer'} className={pathname === '/answer'? 'SideBar_Con SideBar_Con_Select' : 'SideBar_Con'}>
                    <RiFileList2Fill size={18}/>
                    <p>Answers</p>
                </Link>
                <Link to={'/staffanswer'} className={pathname === '/staffanswer'? 'SideBar_Con SideBar_Con_Select' : 'SideBar_Con'}>
                    <RiFileList3Fill size={18}/>
                    <p>Staff Activity</p>
                </Link>
                <Link to={'/report'} className={pathname === '/report'? 'SideBar_Con SideBar_Con_Select' : 'SideBar_Con'}>
                    <RiBarChart2Fill size={18}/>
                    <p>Overall Report</p>
                </Link>
                <Link to={'/target'} className={pathname === '/target'? 'SideBar_Con SideBar_Con_Select' : 'SideBar_Con'}>
                    <RiLineChartLine size={18}/>
                    <p>Targets</p>
                </Link>
                <Link to={'/topreport'} className={pathname === '/topreport'? 'SideBar_Con SideBar_Con_Select' : 'SideBar_Con'}>
                    <RiDashboardFill size={18}/>
                    <p>Board Report</p>
                </Link>
                {/* <Link to={'/neil'} className={pathname === '/neil'? 'SideBar_Con SideBar_Con_Select' : 'SideBar_Con'}>
                    <RiDashboardFill size={18}/>
                    <p>Neil Report</p>
                </Link> */}
            </div>
        )
    }
}
