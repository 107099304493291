import React from 'react'
import './style.css'
import { RiLogoutBoxRLine } from 'react-icons/ri'

export default function Header({title, setCreate, type, setType, btn, month, setMonth}) {
  const logOut = () =>{
    localStorage.removeItem('LT_Admin')
    window?.location?.reload()
  }

  return (
    <div className='Header'>
        <div className='Header_Con'>
          <h2>{title}</h2>
          <div className='FlexCon'/>
          {btn}
          {title === 'Staff Report' || title === "Board Report" ?
          <select value={month} onChange={(e)=>setMonth(e?.target?.value)}>
            {monthNames?.map((itm, ind)=>{
              return <option key={ind}>{itm}</option>
            })}
          </select>
          :
          title === 'Answers'?
          <button onClick={()=>setCreate(true)}>Filter</button>
          :
          title === 'Users'?
          <button onClick={()=>setCreate(true)}>Create User</button>
          : title === 'Targets'?
          <select value={type} onChange={(e)=>setType(e.target.value)}>
            <option>Yearly</option>
            <option>Monthly</option>
          </select>
          : title === 'Overall Report'?
          <select value={type} onChange={(e)=>setType(e.target.value)}>
            <option>Overall</option>
            <option>Courageous</option>
            <option>Helpful</option>
            <option>Relational</option>
            <option>Innovation</option>
            <option>Spiritual</option>
            <option>Together</option>
          </select>
          : null
          }
          <RiLogoutBoxRLine style={{cursor:"pointer"}} onClick={()=>logOut()} size={20}/>
        </div>
    </div>
  )
}

const monthNames = ['September-2024', 'October-2024', 'November-2024', 'December-2024', 'January-2025', 'February-2025', 'March-2025', 'April-2025', 'May-2025', 'June-2025', 'July-2025', 'August-2025', 'September-2025'];