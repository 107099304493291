import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { ApolloClient, InMemoryCache, ApolloProvider, useLazyQuery } from '@apollo/client';
import './App.css';
import SideBar from './layout/sideBar';
import LogIn from './pages/login';
import { GET_USER } from "./graphql/query";
import { useEffect } from "react";
import Home from "./pages/home";
import Answer from "./pages/answer";
import Target from "./pages/target";
import Report from "./pages/report";
import Neil from "./pages/neil";
import Staff from "./pages/staff";
import TopLevel from "./pages/topLevel";

function App() {
  const user = localStorage.getItem('LT_Admin')
  const client = new ApolloClient({
    // uri: 'http://localhost:4000/',
    uri: 'https://server.yfcuk.org',
    cache: new InMemoryCache(),
    headers:{
      Authorization: user || '',
    }
  });
  return (
    <ApolloProvider client={client}>
      <Router>
        <Website user={user}/>
      </Router>
    </ApolloProvider>
  );
}

function Website({user}){
  const [getUser,{loading, data}] = useLazyQuery(GET_USER)

  useEffect(()=>{
    if(user){
      getUser()
    }
  },[user])

  return(
    <div className="App">
          <SideBar user={data?.getAdmin}/>
          <div className="App_Con">
            <Routes>
              {data?.getAdmin ?
                <>
                  <Route path='*' element={<Navigate to={'/'} />} />
                  <Route path='/' element={<Home />} />
                  <Route path='/answer' element={<Answer />} />
                  <Route path='/staffanswer' element={<Staff />} />
                  <Route path='/target' element={<Target />} />
                  <Route path='/report' element={<Report />} />
                  <Route path='/topreport' element={<TopLevel />}/>
                  <Route path='/neil' element={<Neil />}/>
                </>
                :
                <>
                  <Route path='*' element={<Navigate to={'/login'} />} />
                  <Route path='/login' element={<LogIn />} />
                </>
              }
            </Routes>
          </div>
        </div>
  )
}

export default App;
